import { ProposalStatus } from '@store/models/proposals';
import {
  ICreateProposalRequest,
  IGrantCycleResponse,
  IUpdateCreatorRequest,
  IUpdateProposalRequest,
} from '@typings/api';
import { api } from '.';

// Grant cycles
export const getCurrentGrantCycle = async () => api.get<IGrantCycleResponse>('/grantcycles');

// Proposals
export const createProposal = async (data: ICreateProposalRequest) =>
  api.post('/proposal/create', data);

export const getProposalByWallet = async (wallet: string) => api.get(`/proposal/${wallet}`);

export const updateProposal = async (data: IUpdateProposalRequest) =>
  api.put('/proposal/update', data);

export const uploadProposalFile = async (data: FormData) =>
  api.put('/proposal/update', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getProposalsByStatus = async (status: ProposalStatus) =>
  api.get(`/proposal/?status=${status}`);

// Proposal Creator
export const updateCreator = async (data: IUpdateCreatorRequest) =>
  api.put('/creator/update', data);
