import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import useAccountChanged from '../hooks/useAccountChanged';
import useChainChanged from '../hooks/useChainChanged';
import Loading from '../pages/Loading/Loading';
import usePageView from '../hooks/usePageView';
import { routes } from './config';
import useAuth from '@hooks/useAuth';
import PrivateRoute from './PrivateRoute';

const Index = () => {
  usePageView();
  useAccountChanged();
  useChainChanged();
  useAuth();

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes.map(({ path, element: Element, meta }) => {
          if (meta?.requiresAuth) {
            return (
              <Route
                key={path}
                path={path}
                element={
                  <PrivateRoute>
                    <Element />
                  </PrivateRoute>
                }
              />
            );
          }

          return <Route key={path} path={path} element={<Element />} />;
        })}
      </Routes>
    </Suspense>
  );
};

export default Index;
