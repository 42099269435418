import { FC } from 'react';
import styled from 'styled-components';

import assets from '../../assets';
import HomeNavbar from '../../components/NavBar/HomeNavbar';

interface IProps {
  message?: string;
}

const Loading: FC<IProps> = ({ message }) => {
  return (
    <PageContainer>
      <HomeNavbar />
      <Content>
        <div className='content-wrapper'>
          <img src={assets.loader} alt='' />
          <p className='font-size-24 text' style={{ marginTop: '40px' }}>
            {message}
          </p>
        </div>
      </Content>
    </PageContainer>
  );
};

export default Loading;

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
`;

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & .content-wrapper {
    flex-grow: 1;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
    & img {
      animation: rotate 1s linear infinite;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
