import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_KEY } from '../app.config';

const useAccountChanged = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const walletAddress = useSelector((state: RootState) => state.user.walletAddress);

  useEffect(() => {
    if (walletAddress && window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length) {
          dispatch.user.setWalletAddress(accounts[0]);
          localStorage.setItem('connected_wallet', accounts[0]);
          dispatch.user.setToken('');
          localStorage.removeItem(ACCESS_TOKEN_KEY);
        } else {
          dispatch.user.setWalletAddress('');
          dispatch.user.setToken('');
          localStorage.removeItem('connected_wallet');
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line
  }, [walletAddress]);
};

export default useAccountChanged;
