export interface IItemAttributes<ValueType> {
  trait_type: string;
  value: ValueType;
  display_type?: string;
}

export type ItemType = 'hoverboard' | 'jetpack' | 'ava';

export interface IItem {
  animation_url: string;
  attributes: Array<IItemAttributes<string>>;
  background_color: string;
  description: string;
  image_url: string;
  name: string;
  tokenId: number;
  type: ItemType;
  contractAddress: string;
}

export type IJetpack = IItem;

export type IAVA = IItem;

export interface IHoverBoard extends Omit<IItem, 'attributes'> {
  attributes: Array<IItemAttributes<string | number>>;
}

export enum PhaseSlug {
  SUBMIT_PROPOSAL = 'submit-proposal',
  JETPACK_COUNCIL_VOTE = 'jetpack-council-vote',
  DUE_DILIGENCE = 'due-diligence',
  GRANT_REVISIONS = 'grant-revisions',
  AVA_TREASURY_BALLOT = 'ava-treasury-ballot',
  WINNERS_ANNOUNCED = 'winners-announced',
}

export enum PhaseVoteLimit {
  JETPACK_COUNCIL = 6,
  AVA_BALLOT = 3,
}
