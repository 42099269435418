import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IFiltersModel {
  avas: boolean;
  jetpacks: boolean;
  hoverboards: boolean;
}

type Filters = keyof IFiltersModel;

export const filters = createModel<RootModel>()({
  name: 'filters',
  state: {
    avas: true,
    jetpacks: true,
    hoverboards: true,
  } as IFiltersModel,
  reducers: {
    toggleFilter(state, payload: Filters) {
      const localState = state;
      localState[payload] = !localState[payload];
      return localState;
    },
    resetFilters(state) {
      state.avas = true;
      state.jetpacks = true;
      state.hoverboards = true;
    },
  },
});
