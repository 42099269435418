import { FC } from 'react';

import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './theme/GlobalStyles';
import Router from './router/index';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import Loading from './pages/Loading/Loading';
import useKytCheck from './hooks/useKytCheck';
import useLoadData from '@hooks/useLoadData';

const App: FC = () => {
  useKytCheck();
  useLoadData();

  const kytLoading = useSelector((state: RootState) => state.user.kyt.loading);
  const ofacLoading = useSelector((state: RootState) => state.user.ofac.loading);
  const grantCycleLoading = useSelector((state: RootState) => state.proposals.grantCycle.loading);
  const userLoading = useSelector((state: RootState) => state.user.loading);
  const activeCampaignLoading = useSelector((state: RootState) => state.campaign.loading);
  const walletAddress = useSelector((state: RootState) => state.user.walletAddress);

  if (userLoading) {
    return (
      <>
        <BrowserRouter>
          <GlobalStyles />
          <Loading message='Make sure to confirm your sign-in with your wallet' />
        </BrowserRouter>
      </>
    );
  }

  if (
    (kytLoading || ofacLoading || grantCycleLoading || activeCampaignLoading) &&
    Boolean(walletAddress)
  ) {
    return (
      <>
        <BrowserRouter>
          <GlobalStyles />
          <Loading />
        </BrowserRouter>
      </>
    );
  }

  return (
    <BrowserRouter>
      <GlobalStyles />
      <Router />
    </BrowserRouter>
  );
};

export default App;
