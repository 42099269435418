import { connect, login } from '@api/auth';
import { Dispatch, RootState } from '@store/index';
import { getProvider } from '@utils/index';
import { utils } from 'ethers';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_KEY } from '../app.config';

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const isMounted = useRef(false);

  const walletAddress = useSelector((state: RootState) => state.user.walletAddress);

  useEffect(() => {
    (async () => {
      if (isMounted.current && walletAddress) {
        dispatch.user.setUserLoading(true);

        try {
          const provider = await getProvider();
          const signer = provider.getSigner();

          const { data } = await connect(walletAddress);

          const message = data?.data;

          if (!message) throw new Error('No signature message');

          const decodedMessage = utils.toUtf8String(message);

          const signature = await signer.signMessage(decodedMessage);

          const { data: authData } = await login({ signature, wallet_address: walletAddress });

          const token = authData?.data;

          dispatch.user.setToken(token);
          localStorage.setItem('connected_wallet', walletAddress);
          localStorage.setItem(ACCESS_TOKEN_KEY, token);

          window.location.reload();
          // dispatch.user.setUserLoading(false);
        } catch (err) {
          console.error(err);
          dispatch.user.setWalletAddress('');
          dispatch.user.setToken('');
          localStorage.removeItem('connected_wallet');
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          dispatch.user.setUserLoading(false);
          navigate('/');
        }
      }

      isMounted.current = true;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);
};

export default useAuth;
