import { Models } from '@rematch/core';

import { user } from './user';
import { jetpacks } from './jetpacks';
import { hoverboards } from './hoverboards';
import { filters } from './filters';
import { avas } from './avas';
import { proposals } from './proposals';
import { campaign } from './campaign';
export interface RootModel extends Models<RootModel> {
  user: typeof user;
  jetpacks: typeof jetpacks;
  hoverboards: typeof hoverboards;
  avas: typeof avas;
  filters: typeof filters;
  proposals: typeof proposals;
  campaign: typeof campaign;
}

export const models: RootModel = {
  user,
  jetpacks,
  hoverboards,
  avas,
  filters,
  proposals,
  campaign,
};
