import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { FC, ReactElement } from 'react';

const PrivateRoute: FC<{ children: ReactElement }> = ({ children }) => {
  const token = useSelector((state: RootState) => state.user.token);

  if (!token) {
    return <Navigate to='/' replace />;
  }

  return children;
};

export default PrivateRoute;
