import { createModel } from '@rematch/core';
import { ethers } from 'ethers';
import { RootModel } from '.';
import { getProvider } from '../../utils';
import { jaduAvasAddress } from '../../app.config';
import { IAVA } from '../../types/interfaces';
import JaduAvas from '../../contracts/JaduAVA';

import axios from 'axios';

interface IAvaState {
  owned: Array<IAVA>;
  loading: boolean;
}

export const avas = createModel<RootModel>()({
  name: 'avas',
  state: {
    owned: [],
    loading: true,
  } as IAvaState,
  reducers: {
    resetState(state) {
      state.loading = false;
      state.owned = [];
    },
    setOwnedAvas(state, payload: Array<IAVA>) {
      state.owned = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async loadOwnedAvas() {
      dispatch.avas.setLoading(true);

      try {
        const provider = await getProvider();
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();

        const avaContract = new ethers.Contract(jaduAvasAddress!, JaduAvas, signer);

        const tokenIds: number[] = await avaContract.tokensOfOwner(signerAddress);

        const ownedAvas = await Promise.all(
          tokenIds.map(async tokenId => {
            const tokenURI = await avaContract.tokenURI(tokenId);
            const { data } = await axios(tokenURI);
            data.tokenId = data.token_id;
            data.type = 'ava';
            data.contractAddress = jaduAvasAddress;
            return data;
          }),
        );
        dispatch.avas.setOwnedAvas(ownedAvas);
      } catch (err: any) {
        console.error(err.message);
      } finally {
        dispatch.avas.setLoading(false);
      }
    },
  }),
});
