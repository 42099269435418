import { createModel } from '@rematch/core';
import { ethers } from 'ethers';
import { RootModel } from '.';
import { getProvider } from '../../utils';
import { jaduJetpacksAddress } from '../../app.config';
import JaduJetpacks from '../../contracts/JaduJetpacks';

import axios from 'axios';
import { IJetpack } from '../../types/interfaces';

interface IJetpackState {
  owned: Array<IJetpack>;
  loading: boolean;
}

export const jetpacks = createModel<RootModel>()({
  name: 'jetpacks',
  state: {
    owned: [],
    loading: true,
  } as IJetpackState,
  reducers: {
    resetState(state) {
      state.loading = false;
      state.owned = [];
    },
    setOwnedJetpacks(state, payload: Array<IJetpack>) {
      state.owned = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async loadOwnedJetpacks() {
      dispatch.jetpacks.setLoading(true);

      try {
        const provider = await getProvider();
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();

        const jetpackContract = new ethers.Contract(jaduJetpacksAddress!, JaduJetpacks, signer);

        const balanceOf = await jetpackContract.balanceOf(signerAddress);

        const tokenIdsPromises = [];

        for (let i = 0; i < balanceOf; i++) {
          tokenIdsPromises.push(jetpackContract.tokenOfOwnerByIndex(signerAddress, i));
        }

        const tokenIds = await Promise.all(tokenIdsPromises);

        const ownedJetpacks = await Promise.all(
          tokenIds.map(async tokenId => {
            const tokenURI = await jetpackContract.tokenURI(tokenId);
            const { data } = await axios(tokenURI);
            // data.tokenId = +data.name.match(/\d+/)[0];
            data.tokenId = tokenId.toNumber();
            data.image_url = data.image;
            data.type = 'jetpack';
            data.contractAddress = jaduJetpacksAddress;
            return data;
          }),
        );
        dispatch.jetpacks.setOwnedJetpacks(ownedJetpacks);
      } catch (err: any) {
        console.error(err.message);
      } finally {
        dispatch.jetpacks.setLoading(false);
      }
    },
  }),
});
