import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import { store } from './store';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import ReactGA from 'react-ga4';
import { gtmId } from './app.config';

if (gtmId) {
  ReactGA.initialize(gtmId);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
);
