import {
  getCurrentGrantCycle,
  getProposalByWallet,
  getProposalsByStatus,
  updateCreator,
  updateProposal,
  uploadProposalFile,
} from '@api/proposals';
import { createModel } from '@rematch/core';
import { ItemType, PhaseSlug } from '@typings/interfaces';
import { shuffle } from 'lodash';
// import { grantPhaseDates } from '../../app.config';
import { RootModel } from '.';
import { ProposalStep } from '../../pages/Proposals/SubmitProposal';

export enum ProposalStatus {
  JP_COUNCIL = 'JP_COUNCIL',
  AVA_BALLOT = 'AVA_BALLOT',
  WINNER = 'WINNER',
}

export interface ProposalInfo {
  _id: string;
  project_type: string;
  grant_type: string;
  project_time: string;
  title: string;
  summary: string;
  project_goal: string;
  description: string;
  hero_image: string | File;
  lead_name: string;
  asset_pfp: number;
  asset_pfp_type: ItemType;
  asset_pfp_url: string;
  website?: string;
  experience: string;
  pitch_deck?: string | File;
  additional_media?: string[] | File[];
  youtube_video?: string;
  partners?: string;
  is_submitted: boolean;
  status?: ProposalStatus;
  breakdown?: Array<{ task: string; budget: number }>;
  creator?: ProposalCreator;
  updated_json?: string;
  updated_at?: string;
  total_vote_weight: number;
  proposal_vote_weight: number;
  percent_vote_weight: number;
}

export interface ProposalCreator {
  _id: string;
  wallet_address: string;
  email: string;
  twitter: string;
  discord: string;
}

export interface GrantCyclePhase {
  _id: string;
  end_date: number;
  is_active: boolean;
  start_date: number;
  grant_phase: {
    name: string;
    slug: PhaseSlug;
    order: number;
  };
}

export interface GrantCycle {
  _id: string;
  number: number;
  title: string;
  description: string;
  type: 'monthly' | 'special';
  start_date: number;
  end_date: number;
  is_active: boolean;
  phases: GrantCyclePhase[];
}

export interface VotableProposals {
  list: ProposalInfo[];
  loading: boolean;
}

interface IProposalState {
  votable: VotableProposals;
  submission: {
    loading: boolean;
    updatingProposal: boolean;
    currentStep: ProposalStep;
    proposalinfo: ProposalInfo;
    proposalCreator: ProposalCreator;
    updatedProposalInfo: null | ProposalInfo;
    lockSubmission: boolean;
  };
  grantCycle: {
    current: GrantCycle | null;
    loading: boolean;
  };
}

const initialProposalInfo: ProposalInfo = {
  _id: '',
  project_type: '',
  grant_type: '',
  project_time: '',
  title: '',
  summary: '',
  project_goal: '',
  description: '',
  hero_image: '',
  lead_name: '',
  asset_pfp: 0,
  asset_pfp_type: 'ava',
  asset_pfp_url: '',
  website: '',
  experience: '',
  pitch_deck: '',
  additional_media: [],
  youtube_video: '',
  partners: '',
  is_submitted: false,
  total_vote_weight: 0,
  proposal_vote_weight: 0,
  percent_vote_weight: 0,
};

const intialProposalCreator: ProposalCreator = {
  _id: '',
  wallet_address: '',
  email: '',
  twitter: '',
  discord: '',
};

export const proposals = createModel<RootModel>()({
  name: 'proposals',
  state: {
    votable: {
      list: [],
      loading: true,
    },
    submission: {
      loading: true,
      updatingProposal: false,
      currentStep: 'grant-purpose',
      proposalinfo: { ...initialProposalInfo },
      proposalCreator: { ...intialProposalCreator },
      updatedProposalInfo: null,
      lockSubmission: false,
    },
    grantCycle: {
      current: null,
      loading: true,
    },
  } as IProposalState,
  reducers: {
    setCurrentProposalStep(state, payload: ProposalStep) {
      state.submission.currentStep = payload;
    },
    setProposalInfo(state, payload: ProposalInfo) {
      state.submission.proposalinfo = payload;
    },
    setUpdatedProposalInfo(state, payload: ProposalInfo) {
      state.submission.updatedProposalInfo = payload;
    },
    resetProposalInfo(state) {
      state.submission.proposalinfo = { ...initialProposalInfo };
      state.submission.updatedProposalInfo = null;
    },
    setProposalCreator(state, payload: ProposalCreator) {
      state.submission.proposalCreator = payload;
    },
    resetProposalCreator(state) {
      state.submission.proposalCreator = { ...intialProposalCreator };
    },
    setGrantCycle(state, payload: GrantCycle) {
      state.grantCycle.current = payload;
    },
    setGrantCycleLoading(state, payload: boolean) {
      state.grantCycle.loading = payload;
    },
    setSubmissionLoading(state, payload: boolean) {
      state.submission.loading = payload;
    },
    setUpdatingProposal(state, payload: boolean) {
      state.submission.updatingProposal = payload;
    },
    setVotableProposals(state, payload: Partial<VotableProposals>) {
      state.votable = { ...state.votable, ...payload };
    },
    setLockSubmission(state, payload: boolean) {
      state.submission.lockSubmission = payload;
    },
  },
  effects: dispatch => ({
    async loadGrantCycle() {
      dispatch.proposals.setGrantCycleLoading(true);

      try {
        const { data } = await getCurrentGrantCycle();

        const grantCycle = data.data.grantCycle;

        grantCycle.phases = data.data.grantcyclePhases.sort(
          (a, b) => a.grant_phase.order - b.grant_phase.order,
        );

        // TODO: remove this once we have a better way to determine the current phase time
        // grantCycle.phases = grantCycle.phases.map(phase => {
        //   const slug = phase.grant_phase.slug;

        //   phase.start_date = grantPhaseDates[slug].start_date;
        //   phase.end_date = grantPhaseDates[slug].end_date;

        //   return phase;
        // });

        dispatch.proposals.setGrantCycle(grantCycle);
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.proposals.setGrantCycleLoading(false);
      }
    },
    async getProposalDraft(wallet: string) {
      dispatch.proposals.setSubmissionLoading(true);

      try {
        const { data } = await getProposalByWallet(wallet);

        if (data.data.length) {
          const proposal = data.data[0];
          const proposalCreator = proposal.creator;

          delete proposal.creator;

          const updatedProposal = proposal.updated_json ? JSON.parse(proposal.updated_json) : null;

          dispatch.proposals.setProposalInfo(proposal);
          dispatch.proposals.setUpdatedProposalInfo(updatedProposal);
          dispatch.proposals.setProposalCreator(proposalCreator);
          dispatch.proposals.setCurrentProposalStep(updatedProposal?.step || proposal.step);
        } else {
          dispatch.proposals.resetProposalInfo();
          dispatch.proposals.resetProposalCreator();
          dispatch.proposals.setCurrentProposalStep('grant-purpose');
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.proposals.setSubmissionLoading(false);
      }
    },
    async updateProposal({
      id,
      step,
      nextStep,
      updatedData,
    }: {
      id: string;
      step: ProposalStep;
      nextStep: ProposalStep;
      updatedData: Partial<ProposalInfo>;
    }) {
      dispatch.proposals.setUpdatingProposal(true);

      try {
        let data: any;

        if (
          updatedData.hero_image instanceof File ||
          updatedData.pitch_deck instanceof File ||
          updatedData.additional_media?.some(media => media instanceof File)
        ) {
          const formData = new FormData();

          if (updatedData.hero_image) {
            formData.append('hero_image', updatedData.hero_image);
          }

          if (updatedData.pitch_deck) {
            formData.append('pitch_deck', updatedData.pitch_deck);
          }

          if (updatedData.additional_media?.length) {
            updatedData.additional_media.forEach(media => {
              if (media instanceof File) {
                formData.append('additional_media', media, media.name);
              }
            });
          }

          formData.append('id', id);
          formData.append('step', step);

          const { data: uploadedRes } = await uploadProposalFile(formData);
          data = uploadedRes;
        } else {
          const { data: updatedRes } = await updateProposal({ id, step, ...updatedData });
          data = updatedRes;
        }

        const proposal = data.data;
        const updatedProposal = proposal.updated_json ? JSON.parse(proposal.updated_json) : null;

        dispatch.proposals.setProposalInfo(proposal);
        dispatch.proposals.setUpdatedProposalInfo(updatedProposal);

        if (nextStep) {
          dispatch.proposals.setCurrentProposalStep(nextStep);
        }

        if (updatedData.is_submitted) {
          dispatch.proposals.resetProposalInfo();
          dispatch.proposals.resetProposalCreator();
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.proposals.setUpdatingProposal(false);
      }
    },
    async updateCreator({
      id,
      updatedData,
    }: {
      id: string;
      updatedData: Partial<ProposalCreator>;
    }) {
      dispatch.proposals.setUpdatingProposal(true);

      try {
        const { data } = await updateCreator({ id, ...updatedData });

        const creator = data.data;
        dispatch.proposals.setProposalCreator(creator);
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.proposals.setUpdatingProposal(false);
      }
    },
    async getVotableProposals(status: ProposalStatus) {
      dispatch.proposals.setVotableProposals({ loading: true });

      try {
        const { data } = await getProposalsByStatus(status);

        dispatch.proposals.setVotableProposals({ list: shuffle(data.data), loading: false });
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.proposals.setVotableProposals({ loading: false });
      }
    },
  }),
});
