import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';

const useLoadData = () => {
  const dispatch = useDispatch<Dispatch>();

  const walletAddress = useSelector((state: RootState) => state.user.walletAddress);
  const currentChain = useSelector((state: RootState) => state.user.currentChain);

  useEffect(() => {
    if (walletAddress) {
      dispatch.hoverboards.loadOwnedHoverboards();
      dispatch.jetpacks.loadOwnedJetpacks();
      dispatch.avas.loadOwnedAvas();
      dispatch.proposals.loadGrantCycle();
      dispatch.campaign.getActiveCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, currentChain]);
};

export default useLoadData;
