import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import assets from '../../assets';
import theme from '../../theme';

interface IProps {
  title?: string;
}

const HomeNavbar: FC<IProps> = ({ title }) => {
  const navigate = useNavigate();

  return (
    <Navbar>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className='block' onClick={() => navigate('/')}>
          <img src={assets.logo} className='logo' alt='' />
        </div>
        {title && (
          <div className='block page-title'>
            <p className='font-size-24' style={{ color: theme.colors.white }}>
              {title}
            </p>
          </div>
        )}
      </div>
      <div className='row'>
        <div className='block'>
          <a href='https://twitter.com/JaduAVAs' target='_blank' rel='noreferrer'>
            <img src={assets.twitter} alt='' />
          </a>
        </div>
        <div className='block'>
          <a href='https://discord.com/invite/jadu' target='_blank' rel='noreferrer'>
            <img src={assets.discord} alt='' />
          </a>
        </div>
        <div className='block'>
          <a href='https://jadu.ar/act' target='_blank' rel='noreferrer'>
            <p className='font-size-16 bold'>FAQ</p>
          </a>
        </div>
      </div>
    </Navbar>
  );
};

export default HomeNavbar;

const Navbar = styled.div`
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  z-index: 1000;

  & .block {
    letter-spacing: 0.1em;
    cursor: pointer;
    padding-inline: 35px;
    height: 100%;
    display: grid;
    place-items: center;
    border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
  & .page-title {
    color: red !important;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  & .row {
    display: flex;
  }
  @media only screen and (max-width: 576px) {
    padding-inline: 32px;
    height: 56px;
    & .block {
      padding-inline: 0px;
      width: 56px;
      & img {
        max-width: 16px;
        &.logo {
          width: 8px;
        }
      }
    }
  }
`;
