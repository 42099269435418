import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getActiveCampaign as getActiveCampaignApi, getCampaignStats } from '@api/campaign';

export interface Campaign {
  _id: string;
  title: string;
  description: string;
  active: boolean;
  options: Array<{
    label: string;
    value: string;
  }>;
  required_assets: string[];
  voted_tokens: {
    avas: number[];
    jetpack: number[];
    hoverboard: number[];
  };
  updated_at: string;
}

export interface CampaignStats {
  totalScore: number;
  optionsScore: Array<{
    label: string;
    value: string;
    score: number;
  }>;
}

interface ICompaignModel {
  loading: boolean;
  currentCampaign: Campaign | null;
  stats: CampaignStats | null;
}

export const campaign = createModel<RootModel>()({
  name: 'campaign',
  state: {
    loading: true,
    currentCampaign: null,
    stats: null,
  } as ICompaignModel,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setCurrentCampaign(state, payload: Campaign) {
      state.currentCampaign = payload;
    },
    setStats(state, payload: CampaignStats) {
      state.stats = payload;
    },
  },
  effects: dispatch => ({
    async getActiveCampaign() {
      try {
        const { data } = await getActiveCampaignApi();
        dispatch.campaign.setCurrentCampaign(data.campaign);
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.campaign.setLoading(false);
      }
    },
    async getCampaignResults(campaignID: string) {
      try {
        const { data } = await getCampaignStats(campaignID);
        dispatch.campaign.setStats(data);
      } catch (err) {
        console.error(err);
      }
    },
  }),
});
