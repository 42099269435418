import axios from 'axios';
import { ACCESS_TOKEN_KEY, apiUrl, ipinfoToken } from '../app.config';
import {
  IAddKytInfoRequest,
  IKytInfo,
  IWalletConnectionRequest,
  IOfacInfo,
  IFileDownloadLogRequest,
} from '../types/api';

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      config.headers!.Authorization = token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.clear();
      window.location.pathname = '/';
    }
    return Promise.reject(error);
  },
);

export const getKytInfo = async (wallet: string) => api.get<IKytInfo>(`/chainalysis/${wallet}`);

export const getIpInfo = async () => axios.get(`https://ipinfo.io/json?token=${ipinfoToken}`);

export const addIpInfo = async (data: IOfacInfo) => api.post('/chainalysis/ipinfo', data);

export const addKytInfo = async (data: IAddKytInfoRequest) =>
  api.post<IKytInfo>('/chainalysis', data);

// wallet connection logging
export const logWalletConnection = (data: IWalletConnectionRequest) =>
  api.post('/walletconnections', data);

// file download logging
export const logFileDownload = (data: IFileDownloadLogRequest) =>
  api.post('/file-downloading', data);
