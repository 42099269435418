/* eslint-disable @typescript-eslint/no-var-requires */
const assets = {
  arrow: require('./icons/arrow.svg').default,
  logo: require('./icons/logo.svg').default,
  twitter: require('./icons/twitter.svg').default,
  discord: require('./icons/discord.svg').default,
  loader: require('./icons/loader.svg').default,
  dot: require('./icons/dot.svg').default,
  robot: require('./img/robot.png'),
  avaLogo: require('./icons/avaLogo.svg').default,
  star: require('./icons/star.svg').default,
  recGreen: require('./img/recGreen.png'),
  stars: require('./icons/stars.svg').default,
  box: require('./icons/box.svg').default,
  filters: require('./icons/filters.svg').default,
  key: require('./icons/key.svg').default,
  traits: require('./icons/traits.svg').default,
  closeIcon: require('./icons/close.svg').default,
  partner: require('./img/partner.png'),
  phase4: require('./img/phase4.png'),
  sadEmoji: require('./icons/sad-emoji.svg').default,
  robot2: require('./img/robot2.png'),
  avaUnrevealed: require('./img/ava-unrevealed.png'),
  hero: require('./img/hero.png'),
  left: require('./img/left.png'),
  right: require('./img/right.png'),
  circle: require('./img/circle.png'),
  plus: require('./img/plus.png'),
  overview: require('./img/overview.png'),
  review: require('./icons/review.svg').default,
  cross: require('./icons/cross.svg').default,
  leftArrow: require('./icons/leftArrow.svg').default,
  rightArrow: require('./icons/rightArrow.svg').default,
  rightArrowBlack: require('./icons/rightArrowBlack.svg').default,
  plusIcon: require('./icons/plus.svg').default,
  johndoe: require('./img/johndoe.png'),
  homebaseBg: require('./img/homebase-bg.png'),
  arrowUp: require('./icons/arrowUp.svg').default,
  eyeIcon: require('./icons/eye.svg').default,
  map: require('./icons/map.svg').default,
  imageIcon: require('./icons/image.svg').default,
  appInviteCardBg: require('./img/app-invite-card-bg.png'),
  appInviteCard: require('./icons/appInviteCard.svg').default,
  infoIcon: require('./icons/info.svg').default,
  checkIcon: require('./icons/check.svg').default,
  editIcon: require('./icons/edit.svg').default,
  arrowDown: require('./icons/arrowDown.svg').default,
  winnerStar: require('./icons/winnerStar.svg').default,
  images: {
    jaduMerch: require('./img/jadu-merch.png'),
  },
  pdf: {
    grantAgreement: {
      path: require('./pdf/AVA Community Treasury Grants Agreement.05.pdf'),
      name: 'AVA Community Treasury Grants Agreement.05.pdf',
    },
  },
};

export default assets;
