import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:not(svg, svg *) {
    color: ${({ theme }) => theme.colors.white};
  }

  body {
    background-color: ${({ theme }) => theme.colors.black};
  }

  h1,
  .h1 {
    font-size: 32px;
    font-weight: 400;
    font-family: 'NTBau';
    line-height: 112%;
    letter-spacing: 0.1em;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 46px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
      font-size: 56px;
    }
  }

  h2,
  .h2 {
    font-size: 24px;
    line-height: 120%;
    font-weight: 400;
    font-family: 'NTBau';
    letter-spacing: 0.1em;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 40px;
    }
  }

  .font-size-12 {
    font-family: 'FavoritMono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.24px;
    color: ${({ theme }) => theme.colors.white};
  }

  .font-size-16 {
    font-family: 'FavoritMono';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  .font-size-20{
    font-family: "FavoritMono";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  .font-size-24 {
    font-family: 'FavoritMono';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  .font-size-120 {
    font-family: 'FavoritMono';
    font-style: normal;
    font-weight: 400;
    font-size: 120px;
    line-height: 120%;
    color: #1d1e21;
  }

  .bold {
    font-weight: 700;
  }

  a {
    text-decoration: none;
  } 

  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button{
  display: none;
  margin: 0;
}

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    
    .font-size-16 {
      font-size: 12px;
      line-height: 13.5px;
    }
    .font-size-24 {
      font-size: 16px;
      line-height: 19.2px;
    }
    .font-size-120 {
      font-size: 80px;
    }
  }
`;
