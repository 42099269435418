import { Campaign, CampaignStats } from '@store/models/campaign';
import { api } from '.';

export interface ISubmitVote {
  option: string;
  campaign: string;
  voted_tokens: {
    avas: number[];
    jetpack: number[];
    hoverboard: number[];
  };
}

export interface IVoteStatus {
  wallet: string;
  campaignID: string;
}

export interface IVote {
  _id: string;
  updated_at: string;
  created_at: string;
  option: string;
  campaignID: string;
  wallet: string;
  score: number;
  __v: number;
}

export const getCampaignVoteStatus = (campaignID: string) =>
  api.get<{ is_voted: boolean }>(`/campaign/${campaignID}/status`);

export const submitCampaignVote = (data: ISubmitVote) => api.post('/campaign', data);

export const getCampaignStats = (campaignID: string) =>
  api.get<CampaignStats>(`/campaign/${campaignID}/stats`);

export const getActiveCampaign = () => api.get<{ campaign: Campaign }>('/campaign/active');
