import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getKytInfo, getIpInfo, addIpInfo } from '../../api';
import { ACCESS_TOKEN_KEY, ofacBlacklist } from '../../app.config';
import { KytRisk } from '../../types/api';
import { IHoverBoard, IJetpack } from '../../types/interfaces';
import axios from 'axios';
import { getUserVotes } from '@api/voting';
import { ProposalInfo } from './proposals';
import { getGrantAgreementStatus } from '@api/grantAgreement';
import { getCampaignVoteStatus } from '@api/campaign';

export enum VoteType {
  JP_COUNCIL = 'jetpack_council',
  AVA_BALLOT = 'ava_ballot',
}

export interface ProposalVote {
  _id: string;
  proposal: ProposalInfo | string;
  grant_cycle: string;
  type: VoteType;
  vote_score: number;
  wallet_address: string;
  jp_tokens?: number[];
  hb_tokens?: number[];
  ava_tokens?: number[];
  is_vote_locked?: boolean;
  created_at?: string;
  updated_at?: string;
}

interface IUserModel {
  loading: boolean;
  selectedItem: IJetpack | IHoverBoard | null;
  currentChain: string;
  walletAddress: string;
  token: string;
  proposalVotes: ProposalVote[];
  isGrantAgreementSigned: boolean;
  kyt: {
    loading: boolean;
    risk: KytRisk;
  };
  ofac: {
    loading: boolean;
    isBlocked: boolean;
  };
  campaignStatus: {
    loading: boolean;
    hasVoted: boolean;
  };
}

export const user = createModel<RootModel>()({
  name: 'user',
  state: {
    loading: false,
    selectedItem: null,
    currentChain: '',
    walletAddress: localStorage.getItem('connected_wallet') || '',
    token: localStorage.getItem(ACCESS_TOKEN_KEY) || '',
    proposalVotes: [],
    isGrantAgreementSigned: false,
    kyt: {
      loading: true,
      risk: 'Low',
    },
    ofac: {
      loading: true,
      isBlocked: false,
    },
    campaignStatus: {
      loading: true,
      hasVoted: false,
    },
  } as IUserModel,
  reducers: {
    setCurrentChain(state, payload: string) {
      state.currentChain = payload;
    },
    setWalletAddress(state, payload: string) {
      state.walletAddress = payload;
    },
    setToken(state, payload: string) {
      state.token = payload;
    },
    setUserLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setSelectedItem(state, payload: IJetpack | IHoverBoard) {
      state.selectedItem = payload;
    },
    setKyt(state, payload: { loading?: boolean; risk?: KytRisk }) {
      state.kyt.loading = payload.loading ?? state.kyt.loading;
      state.kyt.risk = payload.risk ?? state.kyt.risk;
    },
    setOfac(state, payload: { loading?: boolean; isBlocked?: boolean }) {
      state.ofac.loading = payload.loading ?? state.ofac.loading;
      state.ofac.isBlocked = payload.isBlocked ?? state.ofac.isBlocked;
    },
    setProposalVotes(state, payload: ProposalVote[]) {
      state.proposalVotes = payload;
    },
    setGrantAgreementStatus(state, payload: boolean) {
      state.isGrantAgreementSigned = payload;
    },
    setCampaignState(state, payload: { loading?: boolean; hasVoted?: boolean }) {
      state.campaignStatus.loading = payload.loading ?? state.campaignStatus.loading;
      state.campaignStatus.hasVoted = payload.hasVoted ?? state.campaignStatus.hasVoted;
    },
  },
  effects: dispatch => ({
    async doKytCheck({ wallet }) {
      dispatch.user.setKyt({ loading: true });
      try {
        try {
          const { data } = await getKytInfo(wallet);
          dispatch.user.setKyt({ loading: false, risk: data.risk });
        } catch (err: any) {
          if (axios.isAxiosError(err)) {
            // pass if kyt request fails
            dispatch.user.setKyt({ loading: false, risk: 'Low' });
          } else {
            throw new Error(err);
          }
        }
      } catch (err: any) {
        // pass if kyt request fails
        dispatch.user.setKyt({ loading: false, risk: 'Low' });
        console.error(err);
      }
    },
    async doOfacCheck({ wallet }) {
      dispatch.user.setOfac({ loading: true });
      try {
        try {
          const { data } = await getIpInfo();

          const isBlocked = ofacBlacklist.includes(data?.country);

          dispatch.user.setOfac({ loading: false, isBlocked });

          await addIpInfo({
            wallet,
            country: data.country,
            is_blacklisted: isBlocked,
            metadata: JSON.stringify(data),
          });
        } catch (err: any) {
          if (axios.isAxiosError(err)) {
            // pass if ofac request fails
            dispatch.user.setOfac({ loading: false, isBlocked: false });
          } else {
            throw new Error(err);
          }
        }
      } catch (err: any) {
        // pass if kyt request fails
        dispatch.user.setOfac({ loading: false, isBlocked: false });
        console.error(err);
      }
    },
    async getVotingStreak({
      wallet,
      days = 30,
      type,
    }: {
      wallet: string;
      days?: number;
      type: VoteType;
    }) {
      try {
        const { data } = await getUserVotes({ wallet_address: wallet, days, type });

        dispatch.user.setProposalVotes(data.data as ProposalVote[]);
      } catch (err) {
        console.error(err);
      }
    },
    async loadUserVotes({
      wallet,
      days = 30,
      type,
    }: {
      wallet: string;
      days?: number;
      type: VoteType;
    }) {
      try {
        const { data } = await getUserVotes({ wallet_address: wallet, days, type });

        dispatch.user.setProposalVotes(data.data as ProposalVote[]);
      } catch (err) {
        console.error(err);
      }
    },
    async loadGrantAgreementStatus(_: undefined, state) {
      try {
        const { data } = await getGrantAgreementStatus({
          wallet_address: state.user.walletAddress,
          grant_cycle: state.proposals.grantCycle.current?._id,
        });

        dispatch.user.setGrantAgreementStatus(data.is_signed);
      } catch (err) {
        console.error(err);
      }
    },
    async loadCampaignVoteStatus(campaignID: string) {
      try {
        dispatch.user.setCampaignState({ loading: true });
        const { data } = await getCampaignVoteStatus(campaignID);
        dispatch.user.setCampaignState({ hasVoted: data.is_voted });
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.user.setCampaignState({ loading: false });
      }
    },
  }),
});
