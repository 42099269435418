import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { hexlify } from 'ethers/lib/utils';
import { IHoverBoard, IJetpack } from '../types/interfaces';
import { ipfsGateway, preferredChainId, jaduAvasAddress } from '../app.config';
import JaduAVA from '../contracts/JaduAVA';

dayjs.extend(utc);

export const connectWallet = async () => {
  try {
    if (window.ethereum) {
      const web3modal = new Web3Modal();
      return await web3modal.connect();
    }
    window.open('https://metamask.io/', '_blank');
    return undefined;
  } catch (err) {
    localStorage.removeItem('connected_wallet');
    console.error(err);
    return null;
  }
};

export const getProvider = async () => {
  const connection = await connectWallet();
  return new ethers.providers.Web3Provider(connection);
};

export const getUserAccount = async () => {
  const provider = await getProvider();
  const account = await provider.getSigner().getAddress();
  return account;
};

export const getChainID = async () => {
  const provider = await getProvider();
  const chainId = await provider.getSigner().getChainId();
  return hexlify(chainId);
};

export const setPrefferredChain = async () => {
  const provider = await getProvider();
  const chainId = await getChainID();
  if (chainId !== preferredChainId) {
    await provider.send('wallet_switchEthereumChain', [{ chainId: preferredChainId }]);
  }
};

export const getNewIpfsUrl = (url: string | undefined) => {
  if (!url) return '';

  return url.replace('https://gateway.pinata.cloud', ipfsGateway);
};

export const getJetpackType = (jetpacks: IJetpack) => {
  if (jetpacks && jetpacks.attributes && jetpacks.attributes.length) {
    const nftType = jetpacks.attributes.find(j => j.trait_type === 'TYPE');
    return nftType?.value.trim();
  }
};

export const getHoverboardType = (hoverboard: IHoverBoard) => {
  if (hoverboard && hoverboard.attributes) {
    const hoverboardType = hoverboard.attributes.find(b => b.trait_type === 'TYPE');
    const hoverboardColor = hoverboard.attributes.find(b => b.trait_type === 'COLOR');
    if ((hoverboardType?.value as string)?.includes('Lite')) {
      return `${(hoverboardType?.value as string)?.trim()} ${(
        hoverboardColor?.value as string
      )?.trim()}`;
    }
    return (hoverboardType?.value as string)?.trim();
  }
  return '';
};

export const getAVATotalSupply = async (): Promise<number> => {
  const provider = await getProvider();
  const ava = new ethers.Contract(jaduAvasAddress, JaduAVA, provider);
  const totalSupply = await ava.totalSupply();
  return totalSupply.toNumber();
};

export const padStart = (n: number | string | any, width: number, z?: string) => {
  z = z || '0';
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

// format number with commas
export const formatNumber = (num: number) =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const getFormatedTime = (seconds: number) => {
  if (seconds <= 0) {
    return '00:00:00';
  }

  const daysWithFraction = seconds / 3600 / 24;
  const days = Math.floor(daysWithFraction);

  const hoursWithFraction = (daysWithFraction - days) * 24;
  const hours = Math.floor(hoursWithFraction);

  const minWithFraction = (hoursWithFraction - hours) * 60;
  const min = Math.floor(minWithFraction);

  if (days <= 0) {
    const secWithFraction = (minWithFraction - min) * 60;
    const sec = Math.floor(secWithFraction);

    return `${padStart(hours, 2)}:${padStart(min, 2)}:${padStart(sec, 2)}`;
  }

  return `${padStart(days, 2)}:${padStart(hours, 2)}:${padStart(min, 2)}`;
};

export const truncateString = (value: string): string => {
  const start = value.slice(0, 4);
  const end = value.slice(value.length - 4, value.length);
  return `${start}...${end}`;
};

// function to convert hex to rgba
export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// get file extension
export const getFileExtension = (filename: string) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
};

// pick properties from object
export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
  const ret: any = {};
  keys.forEach(key => {
    ret[key] = obj[key];
  });
  return ret;
};

// get formated file size typescript
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// create a debounce function in typescript
export const debounce = <F extends (...args: any[]) => any>(func: F, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

// clip text after certain length
export const clipText = (text: string, length = 270) => {
  if (text.length > length) {
    return `${text.slice(0, length)}...`;
  }
  return text;
};

// validate youtube url
export const validateYouTubeUrl = (url?: string) => {
  if (!url) return false;
  const regExp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  const match = url.match(regExp);
  return match != null;
};

// get youtube video id from url
export function getYouTubeId(url: string | undefined | null) {
  if (!url) return null;

  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}
