import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { ethers } from 'ethers';
import { getProvider } from '../../utils';

import { jaduHoverbaordsAddress } from '../../app.config';
import JaduHoverboard from '../../contracts/JaduHoverboard';
import axios from 'axios';
import { IHoverBoard } from '../../types/interfaces';

interface IHoverBoardState {
  owned: Array<IHoverBoard>;
  loading: boolean;
}

export const hoverboards = createModel<RootModel>()({
  name: 'hoverboards',
  state: {
    owned: [],
    loading: true,
  } as IHoverBoardState,
  reducers: {
    resetState(state) {
      state.loading = false;
      state.owned = [];
    },
    setOwnedHoverBoards(state, payload: Array<IHoverBoard>) {
      state.owned = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async loadOwnedHoverboards() {
      dispatch.hoverboards.setLoading(true);

      try {
        const provider = await getProvider();
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();

        const hoverboardContract = new ethers.Contract(
          jaduHoverbaordsAddress!,
          JaduHoverboard,
          signer,
        );

        const balanceOf = await hoverboardContract.balanceOf(signerAddress);

        const tokenIdsPromises = [];
        for (let i = 0; i < balanceOf; i++) {
          tokenIdsPromises.push(hoverboardContract.tokenOfOwnerByIndex(signerAddress, i));
        }
        const tokenIds = await Promise.all(tokenIdsPromises);

        const ownedHoverBoards = await Promise.all(
          tokenIds.map(async tokenId => {
            const tokenURI = await hoverboardContract.tokenURI(tokenId);
            const { data } = await axios(tokenURI);
            data.tokenId = data.token_id;
            data.type = 'hoverboard';
            data.contractAddress = jaduHoverbaordsAddress;
            return data;
          }),
        );
        dispatch.hoverboards.setOwnedHoverBoards(ownedHoverBoards);
      } catch (err: any) {
        console.error(err.message);
      } finally {
        dispatch.hoverboards.setLoading(false);
      }
    },
  }),
});
