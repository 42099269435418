import { lazy } from 'react';

export const routes = [
  {
    path: '/',
    element: lazy(() => import('@pages/Home/Home' /* webpackChunkName: "home" */)),
  },
  {
    path: 'homebase',
    element: lazy(() => import('@pages/HomeBase/HomeBase' /* webpackChunkName: "homebase" */)),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'item-view',
    element: lazy(() => import('@pages/ItemView/ItemView' /* webpackChunkName: "item-view" */)),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'proposals/submit',
    element: lazy(
      () => import('@pages/Proposals/SubmitProposal' /* webpackChunkName: "submit-proposal" */),
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'proposals/edit',
    element: lazy(
      () =>
        import(
          '@pages/Proposals/EditProposalConfimation' /* webpackChunkName: "edit-proposal-conformation" */
        ),
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'proposals/vote',
    element: lazy(
      () => import('@pages/Proposals/SubmitVote' /* webpackChunkName: "submit-vote" */),
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'proposals/winners',
    element: lazy(() => import('@pages/Proposals/Winners' /* webpackChunkName: "submit-vote" */)),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'grant-agreement',
    element: lazy(() => import('@pages/GrantAgreement' /* webpackChunkName: "grant-agreement" */)),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'not-found',
    element: lazy(() => import('@pages/NotFound/NotFound' /* webpackChunkName: "not-found" */)),
  },
];
