import { PhaseSlug } from '@typings/interfaces';
import assets from './assets';

export const jaduJetpacksAddress = process.env.REACT_APP_JETPACK_ADDRESS!;

export const jaduHoverbaordsAddress = process.env.REACT_APP_HOVERBOARD_ADDRESS!;

export const jaduAvasAddress = process.env.REACT_APP_AVA_ADDRESS!;

export const jaduAvaDistributorAddress = process.env.REACT_APP_AVA_DISTRIBUTOR!;

export const ipfsGateway = process.env.REACT_APP_IPFS_GATEWAY!;

export const preferredChainId = process.env.REACT_APP_CHAIN_ID!;

export const ipinfoToken = process.env.REACT_APP_IPINFO_TOKEN!;

export const apiUrl = process.env.REACT_APP_API_URL!;

export const ipnsNodeUrl = process.env.REACT_APP_IPNS_NODE_URL!;

export const gtmId = process.env.REACT_APP_GTM_ID!;

export const avaMaxSupply = 11_111;

export const ofacBlacklist = ['CU', 'IR', 'KP', 'RU', 'SY', 'UA'];

export const sigHBSeries = [
  'Snoop Dogg',
  'Mimi',
  'Circuitboard',
  'Trippy',
  'Lewis Hamilton',
  'Grimes',
];

export const oneOnOneHBIds = [81, 4289, 4559, 5766, 3160, 5119];

export const contractAddresses = {
  jetpack: '0xd0F0C40FCD1598721567F140eBf8aF436e7b97cF',
  hoverboard: '0xeDa3b617646B5fc8C9c696e0356390128cE900F8',
};

export const MAX_CARD_WIDTH = 1200;
export const MIN_CARD_WIDTH = 500;

export const CARD_RESPONSIVE_BREAKPOINT = 750;

export const ACCESS_TOKEN_KEY = 'auth.accessToken';

export const VOTING_SCORES = {
  jetpacks: {
    classic: 1,
    pro: 2,
    trippy: 3,
  },
  hoverboards: {
    simple: 0.5,
    signatures: 1,
  },
  avas: 1,
};

export const grantPhaseCopy = {
  default: {
    title: 'AVA Community Treasury cycle will resume shortly',
    description:
      'The next stage of this AVA Community Treasury cycle will begin soon. Learn more at <a href="https://jadu.ar/act" target="_blank">https://jadu.ar/act',
    buttonLable: 'BUY ON OPENSEA',
    link: 'https://opensea.io/collection/jadu',
    target: '_blank',
    icon: '',
  },
  [PhaseSlug.SUBMIT_PROPOSAL]: {
    title: 'AVA Community Treasury is now accepting proposals.',
    description:
      'The proposal submission period has begun. There are {days_left} days left and a total of {amount} proposals submitted so far.',
    buttonLable: 'SUBMIT PROPOSAL',
    link: '/proposals/submit',
    target: '',
    icon: '',
  },
  [PhaseSlug.JETPACK_COUNCIL_VOTE]: {
    title: 'Jetpack Holders can now vote on ACT Proposals.',
    description:
      'The Jetpack Council can now vote on proposals. There are {days_left} days left to vote on {amount} proposals.',
    buttonLable: 'VOTE ON PROPOSALS',
    link: '/proposals/vote',
    target: '',
    icon: '',
  },
  [PhaseSlug.DUE_DILIGENCE]: {
    title: 'Jadu is performing proper due diligence on submitted proposals.',
    description:
      'This week, the Jadu team is taking time to review {amount} submitted proposals. Ballot voting begins in {days_left} days.',
    buttonLable: 'BUY ON OPENSEA',
    link: 'https://opensea.io/collection/jadu',
    target: '_blank',
    icon: '',
  },
  [PhaseSlug.GRANT_REVISIONS]: {
    title: 'Grant Submissions can now be edited and/or revised.',
    description:
      'Grant Submissions can now be edited and/or revised. There are {days_left} days left to edit proposals.',
    buttonLable: 'EDIT YOUR PROPOSAL',
    link: '/proposals/edit',
    target: '',
    icon: assets.editIcon,
  },
  [PhaseSlug.AVA_TREASURY_BALLOT]: {
    title: 'AVA Holders can now vote on ACT Proposals.',
    description:
      'AVA holders can now vote on proposals with their Hoverboard bonus. There are {days_left} days left to vote on {amount} proposals.',
    buttonLable: 'VOTE ON PROPOSALS',
    link: '/proposals/vote',
    target: '',
    icon: '',
  },
  [PhaseSlug.WINNERS_ANNOUNCED]: {
    title: 'AVA Community Treasury winning proposals have now been announced.',
    description: 'Ballots have been counted and winners have been drawn. Congratulations to all!',
    buttonLable: 'VIEW WINNERS',
    link: '/proposals/winners',
    target: '',
    icon: '',
  },
};

// NOTE: This is a temporary solution to adjust the pahse dates quickly. remove later
export const grantPhaseDates = {
  [PhaseSlug.SUBMIT_PROPOSAL]: {
    start_date: 1668439456,
    end_date: 1668595228,
  },
  [PhaseSlug.JETPACK_COUNCIL_VOTE]: {
    start_date: 1668595228,
    end_date: 1668595228,
  },
  [PhaseSlug.DUE_DILIGENCE]: {
    start_date: 0,
    end_date: 0,
  },
  [PhaseSlug.AVA_TREASURY_BALLOT]: {
    start_date: 1668595228,
    end_date: 1668595228,
  },
  [PhaseSlug.WINNERS_ANNOUNCED]: {
    start_date: 1668595228,
    end_date: 1668595228,
  },
};
