import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { getChainID, setPrefferredChain } from '../utils';
import { preferredChainId } from '../app.config';

const useChainChanged = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const walletAddress = useSelector((state: RootState) => state.user.walletAddress);

  useEffect(() => {
    if (walletAddress) {
      (async () => {
        try {
          const currentChain = await getChainID();
          dispatch.user.setCurrentChain(currentChain);
          setPrefferredChain();
          if (window.ethereum) {
            window.ethereum.on('chainChanged', async (chainId: string) => {
              dispatch.user.setCurrentChain(chainId);
              if (chainId !== preferredChainId) {
                setPrefferredChain();
              }
            });
          }
        } catch (err) {
          navigate('/');
          console.error(err);
        }
      })();
    }
    // eslint-disable-next-line
  }, [walletAddress]);
};

export default useChainChanged;
