import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';

function useKytCheck() {
  const dispatch = useDispatch<Dispatch>();
  const walletAddress = useSelector((state: RootState) => state.user.walletAddress);

  useEffect(() => {
    if (walletAddress) {
      dispatch.user.doKytCheck({ wallet: walletAddress });
      dispatch.user.doOfacCheck({ wallet: walletAddress });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);
}

export default useKytCheck;
