import { hexToRgba } from '@utils/index';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    darkGrey: '#1D1E21',
    white: '#F2F8F9',
    lightGrey: '#9AA0AB',
    black: '#000000',
    yellow: '#F6C425',
    yellowGreen: '#C5CF20',
    red: '#D42F35',
    borderColor: hexToRgba('#F2F8F9', 0.2),
  },
  breakpoints: {
    xs: '320px', // min-width
    sm: '576px', // min-width
    md: '768px', // min-width
    lg: '1024px', // min-width
    xl: '1280px', // min-width
    '2xl': '1536px', // min-width
    '3xl': '1920px', // min-width
  },
};

export default theme;
