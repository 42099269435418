import { api } from '.';
import { IRemoveVoteRequest, ISubmitVoteRequest } from '@typings/api';
import { VoteType } from '@store/models/user';

// Proposal Voting
export const voteOnProposal = async (data: ISubmitVoteRequest) => api.post('/voting/submit', data);

export const removeVoteOnProposal = async (data: IRemoveVoteRequest) =>
  api.post('/voting/remove', data);

export const lockVotes = async (data: string[]) =>
  api.post('/voting/lock', {
    votes: data,
  });

export const abstainProposal = async (data: ISubmitVoteRequest) =>
  api.post('/voting/abstain', data);

export const getUserVotes = async (data: {
  wallet_address: string;
  days: number;
  type: VoteType;
}) => api.get('/voting/streak', { params: data });
